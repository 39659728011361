@use "variables";
@import "@syncfusion/ej2-icons/styles/bootstrap";
@import '~leaflet/dist/leaflet.css';

* {
  transition: background-color 200ms linear;
}

body {
  box-sizing: border-box;
}

#viewer-host {
  width: 100%;
  height: 100%;
}

#designer-host {
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}



a {
  text-decoration: none !important;
}

.MuiListSubheader-root {
  background-color: #061a25 !important;
  opacity: 1;
}

.MuiContainer-root {
  padding: 0 !important;
}

.MuiList-padding {
  padding: 0 !important;
}

.MuiCalendarPicker-root {
  background: #2f4756 !important;
}

.css-mvmu1r div button{
  background: #2f4756 !important;
}

.css-yz9k0d-MuiInputBase-input{
  height: 36px !important;
}

.MuiInputBase-input, .MuiOutlinedInput-root {
  border-radius: 4px;
}

.layout {
  position: relative;
  top: 6px;
  left: 6px;
}

#charts_border {
  fill: transparent !important;
}

.scrollbar-container {
  margin-top: 48px !important;
}

/* Component styles */

.main-wrapper {
  //background-color: #fff;
  color: #000;
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
}

.mapping-wrapper {
  background-color: #fff;
  color: #000;
  flex-grow: 1;
  height: 100vh;
}

/* Panel styles */

.panel-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
}

.panel {
  width:15.5%;
  min-width: 240px;
  height: 180px;
  margin-right: 15px;
  margin-bottom: 15px;
  background: #2e4857;
  border-radius: 5px;
  box-shadow: 10px 5px 10px #0b2a3c;
}

/* .panel-header {
  display: flex;
  justify-content: space-between;
  padding: 7px 18px;
  background: #233c4e;
  border-radius: 5px 5px 0 0;
} */

.panel-header__content {
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  cursor: all-scroll;
}

.panel-header__icon {
  cursor: pointer;
}

.panel-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  height: 75%;
  padding-left: 18px;
} 

.panel-content__description {
  margin: 0;
  font-size: 14px;
}

/* .e-toolbar-items {
  width: 100% !important;
}

.e-toolbar-item {
  width: 50% !important;
} */

#switch-list-label-wifi {
  width: 8px;
}

.e-dashboardlayout.e-control .e-panel{
  border: 1px #bbd2ec solid;
}

.e-dashboardlayout.e-control .e-panel:hover {
  border: 1px #bbd2ec solid;
}

#api_dashboard.e-dashboardlayout.e-control .e-panel .e-panel-header {
  background-color: #bbd2ec !important;
  color: #231f1f;
}

//[data-type="rendered-map"] > div {
//  width: fit-content !important;
//  height: fit-content !important;
//}

#api_property .form-label {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.5;
  font-size: 13px;
  font-weight: 500;
}

#api_property .row .e-btn {
  display: inline;
}

#api_dashboard {
  padding: 10px;
  width: 100%;
}
#api_dashboard .panel-content {
  vertical-align: middle;
  font-size: 15px;
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.61);
  text-align: center;
}

#default_dashboard .e-panel {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

#api_dashboard .e-panel-content {
  height: calc(100% - 28px);
  width: 100%;
  display: block;
}

#api_dashboard .e-panel .e-header-text {
  padding: 12px 0 12px 0;
}

#api_dashboard.e-dashboardlayout.e-control .e-panel .e-panel-header {
  font-size: 15px;
  font-weight: 500;
  height: 37px;
  padding: 10px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, .03);
  border-bottom: 0.5px solid #0d6251;
}

.e-dashboardlayout.e-control .e-panel .e-panel-header {
  padding-top: 0;
  padding-bottom: 0;
}

#api_property .row {
  margin-left: 0px;
  margin-right: 0px;
}

#api_property .row-header {
  font-size: 13px;
  font-weight: 500;
}

#api_dashboard #reset {
  display: block;
  margin: 0 auto;
  width: 90px;
}

.property-section.dashboard .e-input-group.e-control-wrapper {
  width:122px;
}

.e-list-parent.e-ul  {
  position: relative !important;
}

.e-treeview .e-list-text { 
  font-size: 16px; 
  font-weight: bold;
} 

.e-treeview.e-fullrow-wrap .e-text-content{
  padding: 0;
}

.e-control {
  border: none;
}

.map-wrapper {
  position: relative;
  display: inline-block;
  margin: 0;
  height: 100%;
  //width: 100%;
}

.map-content {
  height: 100%;
  border: 2px solid rgba(0,0,0,0.2);
}

.syn-map{
  height: 100%;
  width: 100%;
}

.syn-map-actions{
  position: absolute;
  left: 10px;
  top: 70px;
  margin-top: 10px;
  background: white;
  border: 2px solid rgba(0,0,0,0.2);
  background-clip: padding-box;
  border-radius: 4px;
  width: 34px;
  height: 34px;
}

.static-map-actions{
  position: absolute;
  left: 10px;
  margin-top: 10px;
  background: white;
  border: 2px solid rgba(0,0,0,0.2);
  background-clip: padding-box;
  border-radius: 4px;
  width: 30px;
  height: 30px;
}

.syn-map-actions >button{
  padding: 8px;
  line-height: 26px;
  cursor: pointer;
}

.static-map-actions >button{
  padding: 5px;
  line-height: 26px;
  cursor: pointer;
}

.save-map-btn{
  position: absolute;
  left: 10px;
  top: 80px;
  background-color: white;
  /*background-color: #1976d2;*/
  /*color: white;*/
  border-radius: 5px;
  /*border-color: #1976d2;*/
  border-color: white;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}

.map-regions-checkbox-wrapper{
  position: absolute;
  right: 200px;
  margin-top: 90px;
  background: white;
  border: 2px solid rgba(0,0,0,0.2);
  border-radius: 4px;
  background-clip: padding-box;
  width: 212px;
  padding: 0 7px;
  overflow: auto;
}

.map-types-dropdown {
  position: absolute;
  right: 200px;
  padding: 0 7px 5px 7px;
  //margin-top: 10px;
  background: white;
  border: 2px solid rgba(0,0,0,0.2);
  border-radius: 4px;
  margin-top: -10px;
  width: 90%;
}

#mapCustom {
  height: 100%;
}

.dynamic-forms .e-input {
  font-size: 13px !important;
}

.e-treeview .e-list-text {
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  color: black;
}

.dropdown-value-clean-btn {
  margin-left: 5px;
  font-size: 15px;
  border-radius: 50%;
  background-color: #1976d2;
  border: 1px solid #1976d2;
  width: 25px;
  height: 25px;
  color: white;
  padding: 0;
}

.MuiFormControlLabel-label {
  /*font-weight: 400;*/
  font-size: 13px !important;
  /*line-height: 1.5;*/
  /*letter-spacing: 0.00938em;*/
}

.MuiFormControlLabel-root {
  margin-top: 0!important;
}

/*.MuiButtonBase-root-MuiCheckbox {*/
/*  padding: 5px !important;*/
/*}*/

.default .e-menu-wrapper {
  background-color: transparent !important;
}

.green .e-menu-wrapper {
  background-color: transparent !important;
}

.yellow .e-menu-wrapper {
  background-color: transparent !important;
}

.iomist .e-menu-wrapper {
  background-color: transparent !important;
}

.defaultdark .e-menu-wrapper {
  background-color: transparent !important;
}

.greendark .e-menu-wrapper {
  background-color: transparent !important;
}

.yellowdark .e-menu-wrapper {
  background-color: transparent !important;
}

.iomistdark .e-menu-wrapper {
  background-color: transparent !important;
}

.e-menu-wrapper {
  border: none;
}

// .default .e-menu-item:hover {
//   background-color: #5e9de0 !important;
// }

// .green .e-menu-item:hover {
//   background-color: #2ecc71 !important;
// }

// .yellow .e-menu-item:hover {
//   background-color: #2ecc71 !important;
// }

// .iomist .e-menu-item:hover {
//   background-color: #35b0ab !important;
// }

.e-pivot-toolbar {
  border: none !important;
}

button.report-button {
  display: flex;
  align-items: center;
  height: 16.797px;
  background-color: #bbd2ec;
}

.css-1ez4img-MuiPaper-root-MuiAppBar-root {
  box-shadow: none !important;
}

#root > div .css-1fqcua4-MuiPaper-root-MuiAppBar-root {
  box-shadow: none !important;
}

header {
  box-shadow: none !important;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.row > * {
  padding-right: 0px;
  padding-left: 0px;
}

.e-pivotview .e-pivotchart>svg {
  border-bottom: none;
  border-right: none;
  border-left: none;
}

 .grid-item-rendered-map {
   height: fit-content !important;
   width: fit-content !important;
 }

.content-grid {
  width: 100%;
  height: 100%;
  font-size: 100%; /* Adjust the font size as needed */
}

.draggablePanelHeader {
  cursor: grab;
}

.css-3tdvxv-MuiToolbar-root, .css-16g7hxx-MuiToolbar-root, .css-hyum1k-MuiToolbar-root, .css-11t8wa6-MuiToolbar-root  {
  min-height: 50px !important;
}

.sidebar-menu {
  background: #bbd2ec;
  height: 64px;
  width: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  position: fixed;
  left: 0;
  bottom: 0;
}

// .e-fullrow {
//   //background: white !important;
// }

#mainTree[data-theme="dark"] {
   & span {
     color: #fff;
   }

   .e-hover > .e-fullrow {
     background: #525555 !important;
     border-color: #525555 !important;
   }

  .e-icon-expandable {
    color: white;
  }

  .e-icon-collapsible {
    color: white;
  }
}

#mainTree {
  position: relative;
  z-index: auto;
  margin-bottom: 40px;
}

ul  {
  height: 100% !important;
}

#mainTree[data-theme="white"] {
  & span {
    color: #000;
  }

  .e-hover > .e-fullrow {
    background: #f5f5f5 !important;
    border-color: #f5f5f5 !important;
  }

  .e-icon-expandable {
    color: black;
  }

  .e-icon-collapsible {
    color: black;
  }
}

.e-treeview .e-list-item.e-active>.e-text-content .e-list-text,
.e-treeview .e-list-item.e-active>.e-text-content .e-icon-collapsible, 
.e-treeview .e-list-item.e-active>.e-text-content .e-icon-expandable {
  color: #000 !important;
}


.sidebar-menu-settings {
  cursor: pointer;
}

.sss {
  background: white;
  border-radius: 4px;
  padding: 4px;
}

.input-manage-regions {
  width: 100%;
  margin-top: 8px;
  padding: 6px 2px;
  border-radius: 4px;
}
.custom-toast-position {
  top: 60px; /* Adjust the pixel value as needed */
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  min-width: 240px !important;
  min-height: 320px !important;
}

.footer-menu-top {
  background: #bbd2eb;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-marker {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: -15px 0 0 -15px; /* Centers the marker on its position */
  cursor: pointer;
}

.marker-color {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.my-section {
  margin: 6px;
}

.geometry-group {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.geometry-btn {
  padding: 0 4px;
  border: 1px solid #ccc;
  border-bottom-right-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.PivotTableChartTwoTone:before {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="=18"><path d="M0 0h24v24H0z" fill="none"/><path d="M10 8h11V5c0-1.1-.9-2-2-2h-9v5zM3 8h5V3H5c-1.1 0-2 .9-2 2v3zm2 13h3V10H3v9c0 1.1.9 2 2 2zm8 1l-4-4 4-4zm1-9l4-4 4 4z"/><path d="M14.58 19H13v-2h1.58c1.33 0 2.42-1.08 2.42-2.42V13h2v1.58c0 2.44-1.98 4.42-4.42 4.42z"/></svg>');
}

.PublicTwoTone:before {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"/></svg>');
}

.GridOnTwoTone:before {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM8 20H4v-4h4v4zm0-6H4v-4h4v4zm0-6H4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4z"/></svg>');
}

.SummarizeTwoTone:before {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="18" viewBox="0 0 24 24" width="18"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><g><path d="M15,3H5C3.9,3,3.01,3.9,3.01,5L3,19c0,1.1,0.89,2,1.99,2H19c1.1,0,2-0.9,2-2V9L15,3z M8,17c-0.55,0-1-0.45-1-1s0.45-1,1-1 s1,0.45,1,1S8.55,17,8,17z M8,13c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S8.55,13,8,13z M8,9C7.45,9,7,8.55,7,8s0.45-1,1-1 s1,0.45,1,1S8.55,9,8,9z M14,10V4.5l5.5,5.5H14z"/></g></g></svg>');
}

.DashboardTwoTone:before {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"/></svg>');
}

.TableViewTwoTone:before {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16h-5v-5h5v5zm-7 0h-5v-5h5v5zm-7 0v-5h5v5H5zm14-7h-5V7h5v5zm-7 0h-5V7h5v5zm-7 0V7h5v5H5z" opacity=".3"/><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16h-5v-5h5v5zm-7 0h-5v-5h5v5zm-7 0v-5h5v5H5zm14-7h-5V7h5v5zm-7 0h-5V7h5v5zm-7 0V7h5v5H5z"/></svg>');
}

.MuiTablePagination-toolbar{
  align-items: baseline !important;
}

.MuiTablePagination-root {
  overflow: hidden !important;
}

.css-15ghc0m-MuiList-root {
  width: 100% !important;
}