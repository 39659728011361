.custom-one-position-layout {
    height: 100%;
    display: grid;
    grid-template-areas:
        "pos1 pos1 pos1"
        "pos1 pos1 pos1"
        "pos1 pos1 pos1"
        "pos1 pos1 pos1";
    grid-template-columns: 2fr 0.5fr 1.5fr;
    grid-template-rows: auto auto auto auto;
    padding: 6px 24px;
}

.custom-one-position-layout {

    .position-1 {
        display: flex;
        align-items: center;
    }

    .position-1 {
        grid-area: pos1;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        box-sizing: border-box;
        min-height: 32px;
    }
}