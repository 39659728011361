.custom-five-position-layout {
    height: 100%;
    display: grid;
    grid-template-areas:
        "pos1 pos1 pos1"
        "pos2 pos2 pos3"
        "pos4 pos4 pos4"
        "pos5 pos5 pos5";
    grid-template-columns: 2fr 0.5fr 1.5fr;
    grid-template-rows: auto auto auto auto;
    padding: 6px 24px;
}

.custom-five-position-layout {

    .position-2,
    .position-3 {
        display: flex;
        align-items: center;
    }

    .position-1 {
        grid-area: pos1;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        box-sizing: border-box;
        min-height: 32px;
    }

    .position-2 {
        grid-area: pos2;
        font-size: 64px;
        font-weight: 700;
        text-align: center;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        box-sizing: border-box;
        min-height: 90px;
    }

    .position-3 {
        grid-area: pos3;
        font-size: 18px;
        text-align: center;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        box-sizing: border-box;
        min-height: 90px;
    }

    .position-4 {
        grid-area: pos4;
        font-size: 18px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        box-sizing: border-box;
    }

    .position-5 {
        grid-area: pos5;
        font-size: 18px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        box-sizing: border-box;
    }

}