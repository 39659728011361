.custom-card-layout {
  height: 100%;
  display: grid;
  grid-template-areas:
    "pos1 pos1"
    "pos2 pos3";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
}

.custom-card-layout {
  .position-1 {
    grid-area: pos1;
    font-size: 16px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    box-sizing: border-box;
  }

  .position-2 {
    grid-area: pos2;
    font-size: 14px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    box-sizing: border-box;
  }

  .position-3 {
    grid-area: pos3;
    font-size: 14px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    box-sizing: border-box;
  }
}